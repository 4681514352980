<template>
  <sub-nav :pages="pages" />
</template>

<script>
export default {
  name: 'SubNavUsersRoles',
  data() {
    return {}
  },
  computed: {
    pages() {
      return [
        {
          title: 'Users',
          icon: 'user',
          to: 'users'
        },
        {
          title: 'Groups',
          icon: 'users',
          to: 'groups'
        },
        {
          title: 'Roles',
          icon: 'key',
          to: 'roles'
        }
      ]
    }
  },
  props: {},
  components: {}
}
</script>
